const WalkThroughData = [
  {
    id: 1,
    cover: require("../../Assets/Walkthrough/gallery1.jpg"),
    alttag: "Prestige City",
  },
  {
    id: 2,
    cover: require("../../Assets/Walkthrough/gallery2.avif"),
    alttag: "Prestige City",
  },
  {
    id: 3,
    cover: require("../../Assets/Walkthrough/gallery4.webp"),
    alttag: "Prestige City",
  },
  {
    id: 4,
    cover: require("../../Assets/Walkthrough/gallery5.jpg"),
    alttag: "Prestige City",
  },
  
];

export default WalkThroughData;
